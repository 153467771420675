import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dhl-price';
}

(function() {
'use strict';

window.addEventListener('load', ()=>{
  var forms = document.getElementsByClassName('needs-validation');
  
  var validation = Array.prototype.filter.call(forms, (form)=>{
	form.addEventListener('submit', (event)=>{
	  if (form.checkValidity() === false) {
		event.preventDefault();
		event.stopPropagation();
	  }
	  form.classList.add('was-validated');
	}, false);
  });
}, false);
})();